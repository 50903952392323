import { Card, CardBody, Col, Container, Input, InputGroup, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Nav } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Patients } from "../../../utils/Constant";
import { ChangeEvent, forwardRef, InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight, Clock, Download, Edit, Eye, FileText, LogIn, Phone, Trash2 } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { AesEncrypt, AesDecrypt } from "../../../Service/crypto";
import webservice from '../../../Service/webservice';
import DataTable from "react-data-table-component";
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import ReactDatePicker from "react-datepicker";
import axios from "axios";
import { getData,Get_permission } from './store'
import { saveAs } from 'file-saver'
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../ReaduxToolkit/Hooks";
import ReactPaginate from "react-paginate";

interface PatientData {
  id: number;
  appointment: string;
  ClientName: string;
  insure_status: string;
  xml_conversion: string;
  Patient_Parent_id: string;
  patient: {
    dob: string;
    name: string;
    first_name: string;
    last_name: string;
  };
  payer: {
    name: string;
  };
  plan: {
    name: string;
  };
  updated_date: string;
  UserName: string;
  regen_status: string;
  RefId: any;
  Regenratestatus: any;
}
type AudioFile = {
  id: string;
  fileName: string;
};
const CustomHeader = ({ menuActions, handleFilter, value, handlePerPage, rowsPerPage }: any) => {
  return (
    <div className='client-master-list-table-header w-100 py-2'>
      <Row>
        <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
          <div className='d-flex align-items-center me-4 '>
            <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
            <Input
              type='select'
              id='rows-per-page'
              value={rowsPerPage}
              onChange={handlePerPage}
              className='form-control ms-50 larger-select '
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </Input>
          </div>
          {menuActions.includes('add') && (
            <Button tag={Link} to='/BulkUpload' color='primary' className='ms-2 zoom-on-hover'>
              + New Appointment
            </Button>
          )}
        </Col>
        <Col
          lg='6'
          className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-1'
        >
          <InputGroup className="input-group-search mb-2 zoom-on-hover">
            <Input
              type="text"
              placeholder="🔍 Search...."
              value={value}
              onChange={(e) => handleFilter(e.target.value)}
              className="input-search"
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

const Patient = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false); // State for controlling modal visibility
  const [selectedPatientId, setSelectedPatientId] = useState(''); // State to store selected patient ID for audio upload
  const currentDate = new Date();
  const [currentDatePicker, setCurrentDatePicker] = useState<Date | null>(currentDate);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false); // State for controlling loading overlay
  const [loading, setLoading] = useState<boolean>(false);
  const [regenrateLoading, setregenrateLoading] = useState<boolean>(false);
  const store = useSelector((state: any) => state.Patient)
  const [value, setValue] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<any>('CreatedAt'); // Example initial sort column
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const dispatch = useAppDispatch()
  const [eventId, setEventId] = useState<PatientData[]>([]);
  const [eventId1, setEventId1] = useState(0);
  const selectedRowsPerPage: { [key: number]: PatientData[] } = {};
  const action = { fromUser: true };
  const [filteredAuditData, setFilteredAuditData] = useState([]);
  const [audioFiles, setAudioFiles] = useState<AudioFile[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [scrollInnerModal, setScrollInnerModal] = useState(false);
  const [refid, setRefid] = useState<string | null>(null);
  const [menuActions, setMenuActions] = useState<string>('');

useEffect(() => {
    if (localStorage.id !== null) {
      dispatch(Get_permission(localStorage.id)).then((response) => {
        const res1 = response.payload;
        const Action = res1[0].Permission.filter(
          (item:any) => item.MenuKey === "PATIENTS"
        );
        setMenuActions(Action[0].MenuActions);
      });
    }
  }, [localStorage.id])

useEffect(() => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  }, [dispatch, store.data.length, value, rowsPerPage, currentPage, sortColumn]);

const handleReloadTable = () => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  };

const handleFilter = (val: any) => {
    setValue(val)
    dispatch(
      getData({
        q: val,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn,
      })
    )
  }

const handlePerPage = (e: any) => {
    dispatch(
      getData({
        q: value,
        perPage: parseInt(e.target.value),
        page: currentPage,
        sort,
        sortColumn,
      })
    )
    setRowsPerPage(parseInt(e.target.value))
  }

const handlePagination = (page: any) => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: page.selected + 1,
        sort,
        sortColumn,
      })
    )
    setCurrentPage(page.selected + 1)
  }

const dataToRender = () => {
    const filters: any = {
      q: value
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

const handleSort = (column: any, sortDirection: any) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort: sortDirection,
        sortColumn: column.sortField
      })
    )
  }

const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage))
    return (
      <ReactPaginate
        previousLabel={<ChevronLeft size={16} />}
        nextLabel={<ChevronRight size={16} />}
        breakLabel="..."
        pageCount={count || 1}
        onPageChange={handlePagination}
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        containerClassName="pagination-container"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    )
  }

const deletePatientData = async (id: any) => {
    const EncParams = AesEncrypt(id);
    await fetch(webservice + 'api/Patient/DeletePatientData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
  };

const getAuditlogdata = async (data: any) => {
    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/getworktimeAuditlog', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);

    return TempData;
  };

useEffect(() => {
    const fetchData = async () => {
      if (refid !== null) {
        const res = await getAuditlogdata(refid);
        setFilteredAuditData(res);
      }
    };
    fetchData();
  }, [refid]);

const DeleteRow = async (rows: PatientData[] | PatientData) => {
  const Username = localStorage.UserName;
    const deleteRow = async () => {
      Swal.fire({
        title: "Insurance Verification Platform",
        text: "Are you sure do you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ms-1",
        },
        buttonsStyling: false,
        allowOutsideClick: false
      })
        .then(async function (result) {
          if (result.isConfirmed) {
            if (Array.isArray(rows)) {
              const ids = rows.map(row => row.id);
              const Data = rows.map(row => ({
                Username,
                RefId: row.id,
                Name: row.UserName
              }));
              await deletePatientData(ids);
            } else {
              const { id, UserName: Name, RefId } = rows;
              const Data = { Username, RefId, Name };
              await deletePatientData([id]);
            }
            Swal.fire({
              icon: "success",
              title: "Insurance Verification Platform",
              text: "Patient Data has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
              allowOutsideClick: false
            });
            handleReloadTable()
          }
        });
    };
    deleteRow();
  };

const handleOpenWordDocument = async (id: any, patient: any) => {
    const filename = patient.first_name + patient.last_name + '.docx'
    try {
      const EncParams = AesEncrypt(JSON.stringify({ id: id! })); // Add a non-null assertion here
      const response = await fetch(webservice + 'api/AllMappedPatientsData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      });
      const responseData = await response.json();
      const OutData = AesDecrypt(responseData)

      const docxData = Uint8Array.from(atob(OutData.file), (c) => c.charCodeAt(0));
      const blob = new Blob([docxData], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const filename = OutData.filename
      saveAs(blob, filename + '.docx')
      // Check if the file_data key exists
      if (!OutData.file) {
        throw new Error('No file data in response');
      }
    } catch (error) {
      console.error('Error downloading file: ', error);
    }
  };


const handleUploadAudioFile = async (id: any) => {
    const responseaudio = await fetch(webservice + 'api/audiolisting', {
      method: 'POST',
      body: JSON.stringify({ id }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });

    const responseData = await responseaudio.json();
    const formattedData: AudioFile[] = responseData.map((element: any) => ({
      id: element.id, // or use the correct property for ID
      fileName: element.File_name // or use the correct property for file name
    }));
    setAudioFiles(formattedData);
    setSelectedPatientId(id);
    setModalOpen(true); // Open the modal for uploading audio files
  };

const closeModal = () => {
    setSelectedPatientId('');
    setModalOpen(false);
    setCurrentDatePicker(currentDate);
  };

const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setSelectedFile(file);
    }
  };

const handleUpload = async () => {
    setIsLoading(true); // Set loading state to true
    const formattedDate = currentDatePicker
      ? currentDatePicker.toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      })
      : '';
    const formData = new FormData();
    if (selectedFile) {
      formData.append('file', selectedFile);
    } else {
      // Handle the case where no file is selected
      console.error("No file selected");
      setIsLoading(false); // Set loading state to false
      return; // Early return to prevent further execution
    }
    formData.append('date', formattedDate);
    formData.append('id', selectedPatientId);
    try {
      const response = await axios.post(webservice + 'api/Patient/AudioUpload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setModalOpen(false); // Close the modal after upload
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Insurance Verification Platform",
          text: "Audio File Uploaded Successfully.",
          customClass: {
            confirmButton: "btn btn-success",
          },
          allowOutsideClick: false
        });
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
  }
  // Handle individual row checkbox change
  const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
    ({ id, ...props }, ref) => (
      <div style={{  position: 'relative', left: '10px' }}>
        <input type='checkbox' id={id} ref={ref} {...props}/>
      </div>
    )
  );

  const handleChangecheck = useCallback(
    ({ selectedRows }: any) => {
      const selectedEventIds = selectedRows.map((row: any) => row.id);
      setEventId(selectedRows);
      setEventId1(selectedEventIds.length);
      if (!action.fromUser) return;
      selectedRowsPerPage[currentPage] = selectedRows;
    }, [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const downloadRowPatientData = async (ids: any[]) => {
    try {
      // Convert the array of IDs to a JSON string
      const idsString = JSON.stringify(ids);
      // Encrypt the JSON string
      const EncParams = AesEncrypt(idsString);
      const response = await fetch(webservice + 'api/Patient/downloadPatientData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      const OutData = AesDecrypt(responseData);
      if (!OutData.file) {
        throw new Error('No file data in response');
      }
      const zipData = Uint8Array.from(atob(OutData.file), (c) => c.charCodeAt(0));
      const blob = new Blob([zipData], { type: "application/zip" });
      const filename = "Patientdetails";
      const date = new Date();
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const formattedDate = day + month + year;
      saveAs(blob, `${filename}_${formattedDate}.zip`);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const downloadRow = async (rows: PatientData[] | PatientData) => {
    if (Array.isArray(rows)) {
      setLoading(true);
      const ids = rows.map(row => row.Patient_Parent_id);
      const success = await downloadRowPatientData(ids);
      selectedRowsPerPage[currentPage] = []; // Clear selected rows for the current page
      handleChangecheck({ selectedRows: [] }); // Ensure checkboxes are unchecked
      setLoading(false);
      // Show success message
      Swal.fire({
        icon: "success",
        title: "Insurance Verification Platform",
        text: "Patient Data has been Downloaded.",
        customClass: {
          confirmButton: "btn btn-success",
        },
        allowOutsideClick: false
      }).then(() => {
        setLoading(false);
      });
    }
  };
  
  const handleRegenerateDocument = async (Patient_Parent_id: any) => {
    setregenrateLoading(true);
    try {
        const EncParams = AesEncrypt(JSON.stringify({ Patient_Parent_id }));
        const fetchResponse = await fetch(webservice + 'api/Patient/regenratepatients', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });
        const data = await fetchResponse.json();
        const data1 = AesDecrypt(data);
        await axios.get(webservice + 'api/elligibilityapi');
        if (data1 === "S") {
            Swal.fire({
                icon: "success",
                title: "Insurance Verification Platform",
                text: "Patients have been Regenrated.",
                customClass: {
                    confirmButton: "btn btn-success",
                },
                allowOutsideClick: false
            });
            handleReloadTable();
            setregenrateLoading(false);
            // navigate(`/patients`);
        }
    } catch (error) {
        console.error('Error downloading file: ', error);
    }
};

  const CustomLoadingComponent = () => {
    return (
      <div className="">
        {store.no_Data === 'There is no Record Found' ? (
          <h4>
            <p>There are no records to display</p>
          </h4>
        ) : (
          <div className="custom-loader">
            <label className="custom-label">Please wait...</label>
            <div className="custom-loading"></div>
          </div>
        )}
      </div>
    );
  };
  const timezone = moment.tz.guess();
  const Columns = [
    {
      name: "Appointment",
      selector: (row: PatientData) => row.appointment,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row: PatientData) => row.ClientName,
      sortable: true,
    },
    {
      name: "Patient",
      selector: (row: PatientData) => row.patient ? `${row.patient.first_name} ${row.patient.last_name}` : 'N/A',
      sortable: true,
    },
    {
      name: "DOB",
      selector: (row: PatientData) => row.patient ? row.patient.dob : 'N/A',
      sortable: true,
    },
    {
      name: "Payer",
      selector: (row: PatientData) => row.payer ? row.payer.name : 'N/A',
      sortable: true,
    },
    {
      name: "Plan",
      selector: (row: PatientData) => row.plan ? row.plan.name : 'N/A',
      sortable: true,
    },
    {
      name: "Regenrate status",
      selector: (row: PatientData) => row.Regenratestatus ? row.Regenratestatus : 'No',
      sortable: true,
    },
    {
      name: "Updated",
      selector: (row: PatientData) => moment.utc(row.updated_date).tz(timezone).format('MM/DD/YYYY hh:mm:ss A'),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: PatientData) => (
        <>
          {row.xml_conversion === "C" && (
            <>
              <Download size={20} onClick={() => handleOpenWordDocument(row.id, row.patient)} style={{ cursor: "pointer" }} id='DownloadTool' />
              <UncontrolledTooltip placement='top' target='DownloadTool' style={{ backgroundColor: "#355876", color: "white" }}>
                Download Word Output
              </UncontrolledTooltip>
            </>
          )}
          &nbsp;
          &nbsp;
          <FileText size={20}
            id='ViewPatient'
            color='#FFA500'
            onClick={() => {
              startViewTimer();
              navigate(`/patient-eligibility/${row.Patient_Parent_id}`);
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          <UncontrolledTooltip placement='top' target='ViewPatient' style={{ backgroundColor: "#355876", color: "white" }}>
            View Patient Details
          </UncontrolledTooltip>
          {eventId1 <= 1 && (
            menuActions.includes("edit") && (
              <>
                <Edit size={20}
                  id='Edit'
                  color='#35bfbf'
                  onClick={() => {
                    startEditTimer();
                    navigate(`/edit-patient/${row.Patient_Parent_id}`);
                  }}
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                />

                <UncontrolledTooltip placement='top' target='Edit' style={{ backgroundColor: "#355876", color: "white" }}>
                  Edit
                </UncontrolledTooltip>
              </>
            )
          )}
          <Phone size={20} onClick={() => handleUploadAudioFile(row.Patient_Parent_id)} style={{ cursor: "pointer" }} id='Audio' />
          <UncontrolledTooltip placement='top' target='Audio' style={{ backgroundColor: "#355876", color: "white" }}>
            Upload Audio Files
          </UncontrolledTooltip>
          &nbsp;
          &nbsp;
          <Clock size={20}
            id='positionLeft'
            color='#09d5ff'
            onClick={() => {
              setScrollInnerModal(true)
              setRefid(row.Patient_Parent_id)
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          <UncontrolledTooltip placement='top' target='Audio' style={{ backgroundColor: "#355876", color: "white" }}>
            Upload Audio Files
          </UncontrolledTooltip>
          {row.regen_status === "Yes" &&(
                <div>
                    <a className="nav-link" onClick={() => handleRegenerateDocument(row.Patient_Parent_id)}>
                        <LogIn size={20} style={{ cursor: 'pointer' }} id={`regenIcon-${row.id}`} />
                        <UncontrolledTooltip placement="top" target={`regenIcon-${row.id}`}>
                            Regenerate
                        </UncontrolledTooltip>
                    </a>
                </div>
            )}
        </>

      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      },
    },
    headCells: {
      style: {
        backgroundColor: '#355876',
        color: 'white',
        fontSize: '16px',
      },
    },
  };

  const startEditTimer = () => {
    const startTime = new Date().getTime();
    localStorage.setItem('editStartTime', startTime.toString());
  };

  const startViewTimer = () => {
    const startTime = new Date().getTime();
    localStorage.setItem('ViewStartTime', startTime.toString());
  };

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, store.total);

  const handleDownload = async (id: any, fileName: string) => {
    setIsDownloading(true);
    try {
      const response = await fetch(webservice + 'api/audiodownload', {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      // Create a blob from the response
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Use the provided filename
      document.body.appendChild(a); // Append the anchor to the document body
      a.click(); // Programmatically click the anchor to trigger the download
      a.remove(); // Remove the anchor from the document body
  
      // Clean up
      window.URL.revokeObjectURL(url);
      setIsDownloading(false);
    } catch (error) {
      console.error('Download failed:', error);
      setIsDownloading(false);
    }
  };
  
  

  return (
    <>
      <Breadcrumbs mainTitle={Patients} title={Patients} />
      <Container fluid>
        <div>
          <Modal
            style={{ maxWidth: '1500px', maxHeight: '600px' }}
            isOpen={scrollInnerModal}
            toggle={() => setScrollInnerModal(!scrollInnerModal)}
            className='modal-dialog-centered modal-lg'
          >
            <ModalHeader toggle={() => setScrollInnerModal(!scrollInnerModal)}>
              Report Log
            </ModalHeader>
            <ModalBody>
              <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                      <th className="audit-tableHeaderStyle">Date & Time</th>
                      <th className="audit-tableHeaderStyle">Action</th>
                      <th className="audit-tableHeaderStyle">DateWise Work Time</th>
                      <th className="audit-tableHeaderStyle">Total Work Time</th>
                      <th className="audit-tableHeaderStyle">Changed By</th>
                    </tr>
                  </thead>
                  <tbody style={{ alignItems: 'center' }}>
                    {filteredAuditData &&
                      filteredAuditData
                        .sort((a: any, b: any) => new Date(b.Modified).getTime() - new Date(a.Modified).getTime())
                        .map((item: any, index: number) => (
                          <tr key={index}>
                            <td className="audit-tableDataStyle">
                              {(() => {
                                const localDate = moment.utc(item.Modified).local().format('MM/DD/YYYY hh:mm:ss A');
                                return localDate;
                              })()}
                            </td>
                            <td className="audit-tableDataStyle">{item.Action}</td>
                            <td className="audit-tableDataStyle">
                              {item.Edit_time && typeof item.Edit_time === 'object' ? (
                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                  <thead>
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                      <th className="audit-tableHeaderStyle">Date</th>
                                      <th className="audit-tableHeaderStyle">Work Time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.entries(item.Edit_time).map(([date, time], dataIndex) => {
                                      const formattedDate = new Date(date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                      });
                                      return (
                                        <tr key={dataIndex}>
                                          <td className="audit-tableDataStyle">{formattedDate}</td>
                                          <td className="audit-tableDataStyle">
                                            {typeof time === 'string' || typeof time === 'number' ? time : 'N/A'}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              ) : (
                                'N/A'
                              )}
                            </td>
                            <td className="audit-tableDataStyle">
                              {item.Total_worktime && typeof item.Total_worktime === 'string'
                                ? item.Total_worktime
                                : 'N/A'}
                            </td>
                            <td className="audit-tableDataStyle">
                              {item.UserName && Array.isArray(item.UserName)
                                ? item.UserName.join(', ')
                                : item.UserName}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Row>
          <Col sm={12}>
            <Card >
              <CardBody>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <DataTable
                      subHeader={true}
                      responsive={true}
                      onSort={handleSort}
                      data={dataToRender()}
                      columns={Columns}
                      paginationServer
                      customStyles={customStyles}
                      paginationPerPage={rowsPerPage}
                      paginationDefaultPage={currentPage}
                      selectableRows
                      selectableRowsHighlight
                      selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                      onSelectedRowsChange={handleChangecheck}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      clearSelectedRows={loading}
                      noDataComponent={<CustomLoadingComponent />}
                      paginationComponent={CustomPagination}
                      subHeaderComponent={
                        <CustomHeader
                          value={value}
                          menuActions={menuActions}
                          rowsPerPage={rowsPerPage}
                          handleFilter={handleFilter}
                          handlePerPage={handlePerPage}
                        />
                      }
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ paddingLeft: '2%' }}>
                        {store.total && (
                          <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                        )}
                      </div>
                      <div>
                        <CustomPagination />
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div>
              {eventId1 > 0 && menuActions.includes('delete') && (
                <div className="customizer-links open">
                  <Nav className="flex-column nav-pills">
                    <a className="nav-link" onClick={() => DeleteRow(eventId)}>
                      <span>Delete</span>
                      <Trash2 className="trash-icon" size={20} />
                    </a>
                  </Nav>
                </div>
              )}

              {eventId1 > 1 && (
                <div className="customizer1-links open">
                  <Nav className="flex-column nav-pills">
                    <a className="nav-link" onClick={() => downloadRow(eventId)}>
                      <span>Download</span>
                      <Download className="download-icon" size={20} />
                    </a>
                  </Nav>
                </div>
              )}

            </div>
          </Col>
        </Row>
      </Container>
      {loading && (
        <div >
          <div className="loading-overlay">
            <div id="page">
              <div id="container">
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="h3" style={{ color: "#212529", fontSize: "16px", fontWeight: "bold", textAlign: "center", marginTop: "20px" }}>
                  Downloading...
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {regenrateLoading && (
        <div >
          <div className="loading-overlay">
            <div id="page">
              <div id="container">
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="h3" style={{ color: "black" }}>Regenrating...</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}><h3>Upload Audio Files</h3></ModalHeader>
        <ModalBody>
          <Label check>Date of Call Recording: </Label>&nbsp;&nbsp;&nbsp;
          <ReactDatePicker
            id="end-date-picker"
            className="form-control"
            selected={currentDatePicker}
            onChange={(date: Date | null) => setCurrentDatePicker(date)}
            dateFormat="MM-dd-yyyy"
            isClearable
          />
          <br />
          <br />
          <input type="file" accept="audio/*" onChange={handleFileChange} ref={fileInputRef} />

          {/* List of files with download buttons */}
          
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
          <Button color="primary" onClick={handleUpload}>
            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Upload'}
          </Button>
          <br />
          <br />
          
          <div className="audio-files-container">
          <h3>Uploaded Audio Files</h3>
          <br />
          
            {audioFiles.map((file, index) => (
              <div key={index} className="audio-file-item">
                <span className="audio-file-name">{file.fileName}</span>
                {isDownloading ? (
                                        <div className="spinner"></div>
                                    ) : (
                <Download onClick={() => handleDownload(file.id, file.fileName)}  style={{ cursor: 'pointer' }} />
                                    )}
              </div>
            ))}
          </div>

        </ModalFooter>
      </Modal>


    </>
  );
};

export default Patient;
